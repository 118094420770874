import {useContext, useState} from "react";
import {Box, Button, Container, FormControl, FormLabel, Input, Stack, Typography} from "@mui/joy";
import {AuthContext} from "../utils/AuthProvider";
import firebase from "firebase/compat/app";
import "firebase/auth";
import {LOGO_SCRITTA} from "../utils/General";


export function Login() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const {setCurrentUser} = useContext(AuthContext)

    return (
        <Box style={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Container>
                <Stack direction="column" spacing={2}>
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <img
                            src={LOGO_SCRITTA}
                            alt="Logo"
                            style={{filter: "invert(1)", height: "7rem", marginBottom: "4rem"}}
                            width="auto"
                        />
                    </Box>
                    <Typography level="h2" textAlign="center">Control Panel</Typography>
                    <FormControl>
                        <FormLabel>
                            Email
                        </FormLabel>
                        <Input
                            placeholder=""
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.keyCode === 13) {
                                    tryLogIn()
                                }
                            }}
                            autoFocus
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>
                            Password
                        </FormLabel>
                        <Input
                            placeholder=""
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.keyCode === 13) {
                                    tryLogIn()
                                }
                            }}
                        />
                    </FormControl>
                    <Button variant="solid" color="primary"
                            sx={{width: "100%"}}
                            onClick={() => tryLogIn()}
                            disabled={!email || !password}
                    >
                        Accedi
                    </Button>
                </Stack>
            </Container>
        </Box>
    )

    function tryLogIn() {
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
            })
            .catch(e => {
                console.log(e)
                window.alert(e.message)
            })
    }
}